export const makeOpaque = (hex: string, amount: number) => {
  /*
   * makeOpaque: Takes a hex color string (ex: "#aabbcc" or "#abc")
   *  and an amount between 0.0 and 1.0
   *
   *  Returns an rgba string corresponding to the input hex color
   *  with the amount as the rgba opacity (ex: "rgba(170, 187, 204, 0.1)"),
   *  returns an empty string if the input is invalid
   */
  if (
    hex !== undefined &&
    amount !== undefined &&
    (hex.length === 4 || hex.length === 7) &&
    amount >= 0.0 &&
    amount <= 1.0
  ) {
    const normalizedHex =
      hex.length === 4
        ? `#${hex.substring(1, 2).repeat(2)}${hex
            .substring(2, 3)
            .repeat(2)}${hex.substring(3, 4).repeat(2)}`
        : hex;
    const hexPartsAsDecimal = [
      normalizedHex.substring(1, 3),
      normalizedHex.substring(3, 5),
      normalizedHex.substring(5, 7),
    ].map((hexPart: string) => parseInt(hexPart, 16));
    return `rgba(${hexPartsAsDecimal.join(', ')}, ${amount})`;
  } else {
    return '';
  }
};
